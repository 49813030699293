#article-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.829);
    backdrop-filter: blur(5px);
    z-index: 400;
}
.img-modal-wrapper{
    /* padding-left: 5%; */
    height: 100%;
    display: flex;
    flex: 0 0 auto;
    overflow: scroll;
    align-items: center;
    margin-right: 0.5%;
}
.extra-width{
    width: 70vh !important;
}
.article-pages{
    height: 90vh;
}
#grab-scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
#grab-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    flex-direction: inherit;
    display: inherit;
    position: relative;
    will-change: transform;
    overflow-x: auto;
    width: 100vw;
    padding-left: 5%;
}
.close-button{
        position: fixed;
        right: 1.2rem;
        top: 1.2rem;
        cursor: pointer;
        background-color: black;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        border: 0.15rem white solid !important;
        transition: all 0.3s ease;
}

.close-button-path {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    background-color: white;
    transform: scale(0.45);
    -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}
.close-button:hover{
    filter: invert(1);
}
.single-page{
    flex-direction: row;
    overflow: scroll;
    padding: 0 1em 0 1em;
}
.single-page img{
    margin: 0 auto 0 auto;
}