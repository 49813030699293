#scroll-top-arrow {
    background-color: rgba(255, 242, 242, 0.877);
    padding: 0.6rem;
    width: 2.2rem;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 50%;
    font-family: 'Oswald';
    position: fixed;
    bottom: 2.25rem;
    right: 2.25rem;
    text-align: center;
    box-shadow: -0.3rem 0.3rem 0.8rem rgba(0, 0, 0, 0.336);
    color: rgb(100, 100, 100);
    cursor: pointer;
    z-index: 1;
    transition: 0.2s all;
    vertical-align: middle;
    display: inline;
  }

  .up-arrow {
    display: block;
    height: 2.2rem;
    width: 2.2rem;
    background-color: rgb(100, 100, 100);
    -webkit-clip-path: polygon(50% 20%, 70% 40%, 55% 40%, 55% 80%, 45% 80%, 45% 40%, 30% 40%);
    clip-path: polygon(50% 20%, 70% 40%, 55% 40%, 55% 80%, 45% 80%, 45% 40%, 30% 40%);
  }
  #scroll-top-arrow:hover {
    background-color: rgb(100, 100, 100);
    opacity: 0.5;
  }
  #scroll-top-arrow:hover .up-arrow {
    background-color: rgb(163, 206, 228);
  }
  
  @media only screen and (max-width: 768px){
    #scroll-top-arrow {
      z-index: 5;
    }
  }