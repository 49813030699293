#spinner {
    font-size: 4rem;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -1;
    animation: shake 5s ease forwards;
    opacity: 0.8;
    display: block;
    transform: translate(-50%, -50%);
}

@keyframes shake {
    0%{
        transform: translate(-50%, -50%) rotate(0deg) ;
    }
    2.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    5.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    7.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    10%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    12.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    15.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    17.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    20%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    22.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    25.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    27.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    30%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    32.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    35.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    37.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    40%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    42.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    45.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    47.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    50%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    52.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    55.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    57.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    60%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    62.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    65.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    67.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    60%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    62.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    65.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    67.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    70%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    72.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    75.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    77.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    80%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    82.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    85.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    87.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    90%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    92.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    95.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    97.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    100%{
        opacity: 0;
    }
}

