@import url(https://fonts.googleapis.com/css?family=Oswald:200,300,regular,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Special+Elite:regular);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:regular);
@import url(https://fonts.googleapis.com/css?family=Sacramento:regular);

a, a:visited, a:hover{
  text-decoration: none;
  color: #f0827e;

}
html{
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: scroll;
  background-image: url(../src/assets/download.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-color: rgb(255, 255, 255);
  overscroll-behavior: none;
}
#menu-modal::-webkit-scrollbar{
  display: none;
}
body{
  overflow-x: hidden;
}
#news-feed{
  width: 65%;
  margin: auto;
  display:flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: space-around;
  padding: 5.5rem 0rem;
}
.news-nodes{
  font-family: 'Oswald';
  font-size: 0.85rem;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  box-sizing: border-box;
  border-radius: 0.2rem;
  box-shadow: 0 1.5rem 1rem -0.5rem rgba(0, 0, 0, 0.644);
  margin: 1rem;
  padding: 1.5rem;
  width: 25rem;
  height: 12rem;
  color: rgb(228, 227, 227);
  display: flex;
  flex-direction: row;
  transition: 0.4s ease all;
  background-image: linear-gradient(160deg, rgba(0, 0, 0, 0.11) 0%, rgba(0, 0, 0, 0.123) 100%), linear-gradient(0deg, rgba(4, 138, 138, 0.493) 15%, rgba(57, 82, 85, 0.76) 15%);
}
.news-nodes > h2 {
  padding-bottom: 2rem;
}
.news-nodes:hover{
  transform: rotate(0.5deg) scale(1.1) translateY(0.6rem);

}
.news-nodes h2,
.news-nodes:visited{
  margin: auto;
  color: white;
}
.news-nodes:hover > h2 {
  color: rgb(199, 224, 224);
}
#live-feed {
  font-family: 'Oswald';
  font-size: 3.2rem;
  text-align: center;
  margin: auto;
  width: 100%;
  background-color: #f0827e;
  margin: 0;
  height: fit-content;
  padding: 0.2rem;
  color: white;
  position: fixed;
  top: -1px;
  z-index: 5;

}

@media only screen and (max-width: 1350px) {
  .news-nodes{
    width: 35rem;
  }
}

@media only screen and ( max-width: 768px ) {
  #news-feed{
    width: 90%;
  }
  .news-nodes{
    height: 9.5rem;
    font-size: 0.7rem;
    padding: 1.2rem;
  }
}