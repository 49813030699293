.interactive-image{
    position: absolute;
    display: block;
    box-sizing: border-box;
}

.i-container, .i-container *{
    position: relative;
    width: 100%;
    color: rgba(245, 146, 146, 0.849);
    transition: 1s ease all;
    cursor: pointer;
    box-sizing: border-box;

}
.i-container {
    height: 100%;
    box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.226);
    display: block;
}
.i-container *{
    width: 100%;
    transition: 0.3s ease all;
    z-index: 1;
    display: block;
}
.i-text{
    font-family: 'Abril Fatface';
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    background-color: rgba(0, 0, 0, 0.562);
    font-size: 5vw;
    width: 100%;
    height: 100%;
    padding: 1px 0 0 1px;
}
.i-text p{
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    width: 100% !important;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center !important;
}

.i-container:hover .i-text,
.i-container:active .i-text{
    opacity: 1;
}
.i-container img{
    width: 100%;
}
@media only screen and (max-width: 768px) {
    .mobile{
        position: absolute;
        min-width: 300px !important;
        width: 95vw !important;
        left: 0 !important;
        margin: 0 2.5vw 4vw 2.5vw !important;
        height: initial !important;
    }
    .images{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 105vh;
    }
    
    .i-container:hover{
        transform: none !important;
    }
    .interactive-image{
        position: initial;
        z-index: 1;
    }
    .i-text{
        transition: all 0.5s ease-in-out;
        display: flex !important;
        flex-flow: column !important;
        justify-content: space-around !important;
        padding: 0 !important;
        outline: 0em;
        outline-offset: 0em;
        width: 100%;
        height: 100%;
        align-items: center;
      }
    .i-text p {
        width: 100% !important;
        height: none !important;
        position: relative !important;
        text-align: center !important;
        font-size: 1.6em !important;
        padding: 3rem !important;
        margin-top: auto;
        margin-bottom: auto;
    }
    .i-container:hover .i-text{
        outline: 1em rgba(255, 255, 255, 0.15) solid;
        outline-offset: -1em;
    }
    /* .mobile-spacer p{
        padding: 1.5rem !important;
    } */
  }
  
  @media screen and (max-width: 768px) and (orientation: landscape){
    .images{
      margin-top: 135vh !important;
    }
  }
  @media screen and (max-width: 768px) and (orientation: portrait){
    .images{
      margin-top: 105vh !important;
    }
  }