#menu-modal{
    position: fixed;
    top: -1.5%;
    left: 100%;
    z-index: 0;
    width: 30%;
    min-width: 600px;
    height: 101.5%;
    min-height: 281px;
    background-color: #68a5b8f6;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: scroll;
    padding: 0;

  }
  #menu-spans {
    cursor: pointer;
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 300;
    width: 3rem;
    height: 5%;
    display: block;
    margin: 0;
  }
  #menu-spans > span{
    display: block;
    position: relative;
    width: 2rem;
    height: 0.3rem;
    border-radius: 0.5em;
    margin: 0.3rem;
    background-color: rgba(138, 183, 190, 1);
  }
  #menu-container{
    width: 100%;
    min-height: 500px;
    height: 80%;
    position: absolute;
    right: 0;
    margin: auto 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
  }
  .menu-title-containers{
    width: 100%;
    overflow-y: hidden;
    overflow-x: visible;
    perspective: 400px;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .menu-title-containers > * {
    margin: auto 0  auto 0;
  }
  
  .menu-title-containers > a > h2{
    transform: translateY(400%);
    font-family: 'Abril Fatface';
    font-size: 3rem;
    margin-right: 3.5rem;
    margin-left: auto;
    text-align: end !important;
    cursor: pointer;
    filter: drop-shadow( -0.05em 1px 1.4px rgba(139, 0, 0, 0.699));
    z-index: 0;

  }
  .menu-title-containers  a {
    z-index: 0;
    width: 100%;
  }
  @media screen and (min-width: 600px){
    .menu-title-containers > a > h2:hover{
      transition: 0.4s all ease !important;
      transform-origin: 120%;
      transform: rotate(2deg) scale(0.99) rotateY(12deg) !important;
      filter: drop-shadow( -12px 1px 3px rgba(88, 1, 1, 0.356));
    }
  }

  @media only screen and (max-width: 600px) {
    .menu-title-containers > a > h2{
      font-size: 2rem;
    }
    #menu-modal{
      min-width: 100% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    #menu-modal{
      min-width: 80%;
    }
    #menu-container{
      top: 6rem;
      margin: auto 0 auto 0;
    }
    .menu-title-containers > a > h2{
      margin-right: 2.5rem;
      font-size: 2.5rem;
    }
}