.social-modal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.767);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    z-index: 305;
    overflow: scroll;
}
#twitter{
    width: 50% !important;
    min-width: 300px;
    height: 90vh !important;
    flex-direction: column !important;
    justify-content: space-around !important;
    align-content: center !important;
    margin: auto auto !important;
    overflow: scroll;
    border-radius: 0.5em;
}
#instagram{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 80%;
    height: 150%;
}
#instagram > div {
    height: 50% !important;
}
#instagram > a{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10%;
    justify-content: space-around;
}
#instagram > a > iframe{
    height: 40vw;
    width: 80vw;
    position: relative;
    pointer-events: none;
    margin: auto;
}
.snapwidget-widget {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 900px){
    #instagram > a > iframe{
        margin: auto;
        height: 60vw;
        pointer-events: none;

    }
}
@media screen and (max-width: 400px){
    #instagram > a > iframe{
        margin: auto;
        position: relative;
        height: 70vw;
        pointer-events: none;
        /* background-color: white; */
        overflow: scroll;
    }
}
@media screen and (max-width: 569px) and (orientation: landscape){
    #instagram > a > iframe{
        margin: auto;
        position: relative;
        width: 85vh;
        height: 70vh;
    }
}
@media screen and (max-width: 737px) and (orientation: landscape){
    #instagram > a > iframe{
        margin: auto;
        position: relative;
        width: 100vh;
        height: 77vh;
    }
}

.css-xgm0sd {
    display: none !important;
    opacity: 0 !important;
    z-index: -9999;
}