.contact-logo{
    width: 10vw;
    min-width: 90px;
    min-height: 90px;
    height: 10vw;
    transition: 0.4s ease all;
    padding: 1.2vw;
    border-radius: 15%;
    cursor: pointer;
}
.contact-logo:hover{
    transform: scale(1.05);
    background-color: rgba(30, 61, 87, 0.404);
}
#contacts-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    /* z-index: 90; */
}
#contacts{
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-content: center;
    width: 70vw;
    height: 90vh;
    padding: 5vh 15vw 5vh 15vw;
    z-index: 90;
}
.designed{
    position: absolute;
    width: 100%;
    padding: 1em;
    text-align: center;
    bottom: 0;
    padding-bottom: 2vh;
    font-weight: 700;
    color: black;
    transition: 0.4s ease all;
    font-family: 'Oswald';
    /* font-size: 3vw; */
}
.designed:visited{
    color: black;
}
.designed:hover{
    color: rgb(136, 55, 243);
}
#contact-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.829);
    backdrop-filter: blur(5px);
    z-index: 400;
}
.contact-form {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.521);
}
form.contact-form {
    padding: 2rem;
    border-radius: 5px;
    min-width: 280px;
    min-height: 500px;
    background-image: linear-gradient(to bottom right, rgba(168, 223, 236, 0.815), rgba(87, 182, 189, 0.815));
}

@media screen and (max-width: 768px) and (orientation: landscape){
    form.contact-form {
        width: 80%;
        max-width: 500px !important;
        max-height: 350px !important;
        min-height: 300px !important;
    }
    .contact-form > input,
    .contact-form > textarea
    {
        width: 100%;
        font-size: 90% !important;
        padding: 0.2rem !important;
    }
    .contact-form > textarea {
        height: 25vh;
    }
    .contact-form > button {
        cursor: pointer;
        margin-top: 0.5rem !important;
        font-size: 1.1rem !important;
    }

  }
.contact-form * {
    outline: none;
    border: none;
    font-family: 'Oswald';
}
.contact-form > input,
.contact-form > textarea
{
    width: 100%;
    font-size: 110%;
    box-shadow: -0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.219);
    border-radius: 3px;
    padding: 0.4rem;
}
.contact-form > h2 {
    color: rgb(82, 73, 69);
    margin-top: 0;
}

.contact-form > button {
    background-color: white;
    cursor: pointer;
    margin-top: 3rem;
    padding: 0.2rem 2rem;
    font-size: 1.2rem;
    border-radius: 20px;
    transition: .2s all;
    box-shadow: -0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.219);
}
.contact-form > button:hover,
.contact-form > button:focus
{
    background-color: black;
    color: white;
    transform: scale(1.1) translateY(-5px);
    box-shadow: -0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.445);
}
.contact-form > button:active {
    background-color: rgb(56, 55, 55);
    transform: scale(1.05) translateY(-2px);
    box-shadow: 0rem 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.719);
}
.contact-form *:not(:last-child) {
    margin-bottom: 1rem;
}