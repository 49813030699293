.button {
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    height: 3.7rem;
    width: 3.7rem;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: filter .2s ease;
    transform: translateY(-50%);
    border: 3px rgb(59, 59, 59) solid;
}
.right-arrow {
    position: absolute;
    background-color: rgba(59, 59, 59);
    height: 3.4rem;
    width: 3.4rem;
    -webkit-clip-path: polygon(32% 32%, 45% 20%, 75% 50%, 45% 80%, 32% 68%, 50% 50% );
    clip-path: polygon(32% 32%, 45% 20%, 75% 50%, 45% 80%, 32% 68%, 50% 50% );
}
.left-arrow {
    position: absolute;
    display: block;
    background-color: rgba(59, 59, 59);
    height: 3.4rem;
    width: 3.4rem;
    -webkit-clip-path: polygon(68% 32%, 55% 20%, 25% 50%, 55% 80%, 68% 68%, 50% 50% );
    clip-path: polygon(68% 32%, 55% 20%, 25% 50%, 55% 80%, 68% 68%, 50% 50% );
}
#img-back-button {
    left: 3rem;
}
#img-forward-button {
    right: 3rem;
}
.button:hover{
    filter: invert(100%);
}
.smaller {
    width: 75%;
    height: 75%;
}
@media screen and (max-width: 768px) {
    .button{
        top: 88%;
        height: 2.5rem ;
        width: 2.5rem ;
    }
    .left-arrow,
    .right-arrow {
        height: 2.2rem ;
        width: 2.2rem ; 
    }

    #img-back-button {
        left: 6rem;
    }
    #img-forward-button {
        right: 6rem;
    }
    .smaller{
        height: 50% !important;
        margin-top: 30% !important;
    }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    .smaller {
        margin-top: 12% !important;
    }
}