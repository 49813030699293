#landing-text-presenter {
    font-size: max(6rem, 12vw) !important;
    filter: drop-shadow(0.1rem 0rem 1rem rgba(0, 0, 0, 0.808));
}
#pres1{
    width: 40vw;
    background-color: rgba(216, 205, 143, 0.63);
}
#pres2{
    width: 45vw;
    background-color: lightblue;
}
#pres3{
    width: 55vw;
    background-color: rgba(70, 228, 194, 0.527);
}
#scran-podcast{
    margin: auto;
    padding-right: 5vw;
}
#p1-container:hover{
    transform: translate(4em) scale(0.95);
}
#p2-container:hover {
    transform: translate(-1rem) scale(0.99) rotate(1deg);
}
#p3-container:hover {
    transform: translate(0.5em) translateY(0.5em);
}

#p3-container:hover .i-text{
    outline: 1em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -0.5em;
}

#bbc-reel{
    width: 90%;
    height: 90%;
}
.ext-iframe {
    margin: auto;
}