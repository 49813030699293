@import url(https://fonts.googleapis.com/css?family=Oswald:200,300,regular,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Special+Elite:regular);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface:regular);
@import url(https://fonts.googleapis.com/css?family=Sacramento:regular);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:visited, a:hover{
  text-decoration: none;
  color: #f0827e;

}
html{
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: scroll;
  background-image: url(/static/media/download.7e85ac1d.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-color: rgb(255, 255, 255);
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}
#menu-modal::-webkit-scrollbar{
  display: none;
}
body{
  overflow-x: hidden;
}
#news-feed{
  width: 65%;
  margin: auto;
  display:flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: space-around;
  padding: 5.5rem 0rem;
}
.news-nodes{
  font-family: 'Oswald';
  font-size: 0.85rem;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  box-sizing: border-box;
  border-radius: 0.2rem;
  box-shadow: 0 1.5rem 1rem -0.5rem rgba(0, 0, 0, 0.644);
  margin: 1rem;
  padding: 1.5rem;
  width: 25rem;
  height: 12rem;
  color: rgb(228, 227, 227);
  display: flex;
  flex-direction: row;
  transition: 0.4s ease all;
  background-image: linear-gradient(160deg, rgba(0, 0, 0, 0.11) 0%, rgba(0, 0, 0, 0.123) 100%), linear-gradient(0deg, rgba(4, 138, 138, 0.493) 15%, rgba(57, 82, 85, 0.76) 15%);
}
.news-nodes > h2 {
  padding-bottom: 2rem;
}
.news-nodes:hover{
  transform: rotate(0.5deg) scale(1.1) translateY(0.6rem);

}
.news-nodes h2,
.news-nodes:visited{
  margin: auto;
  color: white;
}
.news-nodes:hover > h2 {
  color: rgb(199, 224, 224);
}
#live-feed {
  font-family: 'Oswald';
  font-size: 3.2rem;
  text-align: center;
  margin: auto;
  width: 100%;
  background-color: #f0827e;
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem;
  color: white;
  position: fixed;
  top: -1px;
  z-index: 5;

}

@media only screen and (max-width: 1350px) {
  .news-nodes{
    width: 35rem;
  }
}

@media only screen and ( max-width: 768px ) {
  #news-feed{
    width: 90%;
  }
  .news-nodes{
    height: 9.5rem;
    font-size: 0.7rem;
    padding: 1.2rem;
  }
}
#home1{
    width: 30vw;
    background-color: rgba(199, 236, 247, 0.541);
}
#home2{
    width: 42vw;
    background-color: rgba(252, 217, 217, 0.562);
}
#home3{
    width: 40vw;
    background-color: rgba(224, 208, 178, 0.658);
}
#home4{
    width: 100%;
    background-color: rgb(234, 221, 255);
    overflow: hidden;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.473);
}

#h4-container, #h4-container *{
    transition: 4s ease all !important;
}

#h3-container #h3-text{
    padding: 5vw;
}
#h4-container #h4-text{
    bottom: 0px;
    height: 100%;
    padding: 5vw;
}
#h4-text p{
    text-align: right !important;
}
#h2-container:hover{
transform: translateX(-1em) translateY(1em) rotateZ(0.3deg);
}
#h3-container:hover, #h1-container:hover{
    transform: translateX(1em) translateY(-1em) rotateZ(-1deg);
}
#h4-container:hover{
    transform: translate(-15em);
}
@media only screen and ( max-width: 768px ) {
    #h4-text p{
        text-align: center !important;
    }
}

#landing-image{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    z-index: 1;
  }
  #landing-image img{
    position: absolute;
    height: 120%;
  }

  #presenter {
    left: 50%;
    transform: translate(-50%);
  }
  .header-wrapper{
    height:20%;
    width: 100%;
    position: absolute;
    top: 65vh;
    display: flex; 
    flex-flow: column;
    justify-content: space-around;
  }
  .header-wrapper > h1{
    text-align: center;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    font-size: clamp(3.5rem, 10vw, 15rem);
    color: white;
    font-family:  'Sacramento', Arial, Helvetica, sans-serif;
    display: inline;
    margin: 0;
    margin-block: 0 !important;
  }
  .header-wrapper> h2{
    margin: 0;
    margin-block: 0;
    width: 100%;
    font-family: 'Special Elite';
    position: relative;
    font-size: 3vw;
    color: white;
    text-align: center;
  }

  #writer{
    display: inline-block;
    width: 11vw !important;
  }
  .Typewriter{
    margin-right: 0.1em ;
    display: inline;
  }
  
  @media only screen and (max-width: 768px) {
    #landing-image{
      position: absolute;
    }
  }
  @media screen and (orientation:portrait) {
    #landing-image{
      height: 100vh;
    }
    #landing-image img{
      height: 100%;
      margin-left: -25%;
    }
    .header-wrapper{
      top: 65vh !important;
    }
  }
  @media screen and (orientation:landscape) {
    #landing-image{
      height: 125vh;
    }
  }
.interactive-image{
    position: absolute;
    display: block;
    box-sizing: border-box;
}

.i-container, .i-container *{
    position: relative;
    width: 100%;
    color: rgba(245, 146, 146, 0.849);
    transition: 1s ease all;
    cursor: pointer;
    box-sizing: border-box;

}
.i-container {
    height: 100%;
    box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.226);
    display: block;
}
.i-container *{
    width: 100%;
    transition: 0.3s ease all;
    z-index: 1;
    display: block;
}
.i-text{
    font-family: 'Abril Fatface';
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    background-color: rgba(0, 0, 0, 0.562);
    font-size: 5vw;
    width: 100%;
    height: 100%;
    padding: 1px 0 0 1px;
}
.i-text p{
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    width: 100% !important;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center !important;
}

.i-container:hover .i-text,
.i-container:active .i-text{
    opacity: 1;
}
.i-container img{
    width: 100%;
}
@media only screen and (max-width: 768px) {
    .mobile{
        position: absolute;
        min-width: 300px !important;
        width: 95vw !important;
        left: 0 !important;
        margin: 0 2.5vw 4vw 2.5vw !important;
        height: auto !important;
        height: initial !important;
    }
    .images{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 105vh;
    }
    
    .i-container:hover{
        transform: none !important;
    }
    .interactive-image{
        position: static;
        position: initial;
        z-index: 1;
    }
    .i-text{
        transition: all 0.5s ease-in-out;
        display: flex !important;
        flex-flow: column !important;
        justify-content: space-around !important;
        padding: 0 !important;
        outline: 0em;
        outline-offset: 0em;
        width: 100%;
        height: 100%;
        align-items: center;
      }
    .i-text p {
        width: 100% !important;
        height: none !important;
        position: relative !important;
        text-align: center !important;
        font-size: 1.6em !important;
        padding: 3rem !important;
        margin-top: auto;
        margin-bottom: auto;
    }
    .i-container:hover .i-text{
        outline: 1em rgba(255, 255, 255, 0.15) solid;
        outline-offset: -1em;
    }
    /* .mobile-spacer p{
        padding: 1.5rem !important;
    } */
  }
  
  @media screen and (max-width: 768px) and (orientation: landscape){
    .images{
      margin-top: 135vh !important;
    }
  }
  @media screen and (max-width: 768px) and (orientation: portrait){
    .images{
      margin-top: 105vh !important;
    }
  }
#spinner {
    font-size: 4rem;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -1;
    animation: shake 5s ease forwards;
    opacity: 0.8;
    display: block;
    transform: translate(-50%, -50%);
}

@keyframes shake {
    0%{
        transform: translate(-50%, -50%) rotate(0deg) ;
    }
    2.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    5.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    7.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    10%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    12.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    15.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    17.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    20%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    22.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    25.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    27.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    30%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    32.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    35.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    37.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    40%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    42.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    45.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    47.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    50%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    52.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    55.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    57.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    60%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    62.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    65.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    67.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    60%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    62.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    65.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    67.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    70%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    72.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    75.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    77.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    80%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    82.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    85.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    87.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    90%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    92.5% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    95.0% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    97.5% {
        transform: translate(-50%, -50%) rotate(3deg);
    }
    100%{
        opacity: 0;
    }
}


.button {
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    height: 3.7rem;
    width: 3.7rem;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: -webkit-filter .2s ease;
    transition: filter .2s ease;
    transition: filter .2s ease, -webkit-filter .2s ease;
    transform: translateY(-50%);
    border: 3px rgb(59, 59, 59) solid;
}
.right-arrow {
    position: absolute;
    background-color: rgba(59, 59, 59);
    height: 3.4rem;
    width: 3.4rem;
    -webkit-clip-path: polygon(32% 32%, 45% 20%, 75% 50%, 45% 80%, 32% 68%, 50% 50% );
    clip-path: polygon(32% 32%, 45% 20%, 75% 50%, 45% 80%, 32% 68%, 50% 50% );
}
.left-arrow {
    position: absolute;
    display: block;
    background-color: rgba(59, 59, 59);
    height: 3.4rem;
    width: 3.4rem;
    -webkit-clip-path: polygon(68% 32%, 55% 20%, 25% 50%, 55% 80%, 68% 68%, 50% 50% );
    clip-path: polygon(68% 32%, 55% 20%, 25% 50%, 55% 80%, 68% 68%, 50% 50% );
}
#img-back-button {
    left: 3rem;
}
#img-forward-button {
    right: 3rem;
}
.button:hover{
    -webkit-filter: invert(100%);
            filter: invert(100%);
}
.smaller {
    width: 75%;
    height: 75%;
}
@media screen and (max-width: 768px) {
    .button{
        top: 88%;
        height: 2.5rem ;
        width: 2.5rem ;
    }
    .left-arrow,
    .right-arrow {
        height: 2.2rem ;
        width: 2.2rem ; 
    }

    #img-back-button {
        left: 6rem;
    }
    #img-forward-button {
        right: 6rem;
    }
    .smaller{
        height: 50% !important;
        margin-top: 30% !important;
    }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    .smaller {
        margin-top: 12% !important;
    }
}
#article-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.829);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 400;
}
.img-modal-wrapper{
    /* padding-left: 5%; */
    height: 100%;
    display: flex;
    flex: 0 0 auto;
    overflow: scroll;
    align-items: center;
    margin-right: 0.5%;
}
.extra-width{
    width: 70vh !important;
}
.article-pages{
    height: 90vh;
}
#grab-scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
#grab-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    flex-direction: inherit;
    display: inherit;
    position: relative;
    will-change: transform;
    overflow-x: auto;
    width: 100vw;
    padding-left: 5%;
}
.close-button{
        position: fixed;
        right: 1.2rem;
        top: 1.2rem;
        cursor: pointer;
        background-color: black;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        border: 0.15rem white solid !important;
        transition: all 0.3s ease;
}

.close-button-path {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    background-color: white;
    transform: scale(0.45);
    -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}
.close-button:hover{
    -webkit-filter: invert(1);
            filter: invert(1);
}
.single-page{
    flex-direction: row;
    overflow: scroll;
    padding: 0 1em 0 1em;
}
.single-page img{
    margin: 0 auto 0 auto;
}
#scroll-top-arrow {
    background-color: rgba(255, 242, 242, 0.877);
    padding: 0.6rem;
    width: 2.2rem;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 50%;
    font-family: 'Oswald';
    position: fixed;
    bottom: 2.25rem;
    right: 2.25rem;
    text-align: center;
    box-shadow: -0.3rem 0.3rem 0.8rem rgba(0, 0, 0, 0.336);
    color: rgb(100, 100, 100);
    cursor: pointer;
    z-index: 1;
    transition: 0.2s all;
    vertical-align: middle;
    display: inline;
  }

  .up-arrow {
    display: block;
    height: 2.2rem;
    width: 2.2rem;
    background-color: rgb(100, 100, 100);
    -webkit-clip-path: polygon(50% 20%, 70% 40%, 55% 40%, 55% 80%, 45% 80%, 45% 40%, 30% 40%);
    clip-path: polygon(50% 20%, 70% 40%, 55% 40%, 55% 80%, 45% 80%, 45% 40%, 30% 40%);
  }
  #scroll-top-arrow:hover {
    background-color: rgb(100, 100, 100);
    opacity: 0.5;
  }
  #scroll-top-arrow:hover .up-arrow {
    background-color: rgb(163, 206, 228);
  }
  
  @media only screen and (max-width: 768px){
    #scroll-top-arrow {
      z-index: 5;
    }
  }
#landing-text-interiors {
    font-size: max(7rem, 12vw) !important;
    -webkit-filter: drop-shadow(0.1rem 0rem 1rem black);
            filter: drop-shadow(0.1rem 0rem 1rem black);
}
#ints1{
    width: 35vw;
    min-width: 600px;
    background-color: rgba(199, 236, 247, 0.541);
  }
  
#ints2{
    background-color: rgba(252, 217, 217, 0.562);
    width: 70%;
  }
#ints3{
    background-color: rgba(157, 179, 132, 0.349);
    width: 43%;
}
#ints4{
    width: 37%;
    background-color: rgb(0, 0, 0);
}
#ints5{
    width: 80%;
}

#ints6{
    width: 45%;
    background-color: rgba(255, 215, 142, 0.699);
}
/* #ints7{
    width: 35%;
    background-color: rgba(233, 139, 201, 0.623);
} */
#ints8{
    width: 35%;
    background-color: rgba(158, 187, 121, 0.349);
}
#ints9{
    width: 82%;
    background-color: rgba(143, 33, 33, 0.432);
}
#ints10{
    width: 40%;
    background-color: rgba(221, 81, 174, 0.24);
}
#ints11{
    width: 25%;
    background-color: rgba(255, 238, 0, 0.486);
}
#ints12{
    width: 40%;
    background-color: rgba(108, 192, 171, 0.562);
}
#ints13{
    width: 50%;
    background-color: rgb(235, 235, 235);
}
#ints14{
    width: 30%;
    background-color: rgba(255, 99, 71, 0.315);
}
#ints15{
    width: 50%;
}
#ints16{
    width: 50%;
    background-color: rgba(194, 133, 77, 0.541) ;
}
#ints17{
    width: 30vw;
    background-color: rgba(7, 197, 0, 0.356);
}
#ints18{
    width: 50vw;
    background-color: rgba(100, 100, 100, 0.445);
}
#ints19{
    width: 30vw;
    background-color: rgba(197, 0, 0, 0.356);
}
#ints20 {
    width: 30vw;
    background-color: rgba(33, 54, 121, 0.486);
}
#i5-container:hover .i-text,
#i12-container:hover .i-text,
#i15-container:hover .i-text
{
    outline: 1em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -1em;
}
#i5-text, #i12-text, #i15-text{
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    padding: 0;
    outline: 0em;
    outline-offset: 0em;
}
#i5-text p, #i12-text p, #i15-text p{
    width: 90% !important;
    position: relative !important;
}
#i2-container:hover, 
/* #i7-container:hover,  */
#i11-container:hover, 
#i17-container:hover{
    transform: translateX(-1em) translateY(1em) rotateZ(0.3deg);
}
#i3-container:hover, #i1-container:hover, #i6-container:hover{
    transform: translateX(1em) translateY(-1em) rotateZ(-1deg);
}
#i4-container:hover{
  transform: translate(-2em);
}
#i8-container:hover{
    transform: translate(2em);
}
#i9-container:hover
{
    transform: translate(-7em) scale(1.02);
}
#i10-container:hover{
    transform: translate(1em) translateY(1em) rotate(0.7deg);
}
#i13-container:hover{
    transform: translateY(1em) scale(0.98);
}
#i14-container:hover{
    transform: translate(-1em) translateY(-1em) rotate(0.7deg);
}
#i18-container:hover{
    transform: scale(0.95);
}
#i19-container:hover{
    transform: translateY(-2vw) scale(0.95);
}
#i9-container:hover,
#i16-container:hover
{
    transform: translate(-3em) scale(1.02);
}
#i4-text p{
    font-size: 4vw;
}
#i8-text p{
    font-size: 3.5vw;
}
#menu-modal{
    position: fixed;
    top: -1.5%;
    left: 100%;
    z-index: 0;
    width: 30%;
    min-width: 600px;
    height: 101.5%;
    min-height: 281px;
    background-color: #68a5b8f6;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: scroll;
    padding: 0;

  }
  #menu-spans {
    cursor: pointer;
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 300;
    width: 3rem;
    height: 5%;
    display: block;
    margin: 0;
  }
  #menu-spans > span{
    display: block;
    position: relative;
    width: 2rem;
    height: 0.3rem;
    border-radius: 0.5em;
    margin: 0.3rem;
    background-color: rgba(138, 183, 190, 1);
  }
  #menu-container{
    width: 100%;
    min-height: 500px;
    height: 80%;
    position: absolute;
    right: 0;
    margin: auto 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
  }
  .menu-title-containers{
    width: 100%;
    overflow-y: hidden;
    overflow-x: visible;
    perspective: 400px;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .menu-title-containers > * {
    margin: auto 0  auto 0;
  }
  
  .menu-title-containers > a > h2{
    transform: translateY(400%);
    font-family: 'Abril Fatface';
    font-size: 3rem;
    margin-right: 3.5rem;
    margin-left: auto;
    text-align: end !important;
    cursor: pointer;
    -webkit-filter: drop-shadow( -0.05em 1px 1.4px rgba(139, 0, 0, 0.699));
            filter: drop-shadow( -0.05em 1px 1.4px rgba(139, 0, 0, 0.699));
    z-index: 0;

  }
  .menu-title-containers  a {
    z-index: 0;
    width: 100%;
  }
  @media screen and (min-width: 600px){
    .menu-title-containers > a > h2:hover{
      transition: 0.4s all ease !important;
      transform-origin: 120%;
      transform: rotate(2deg) scale(0.99) rotateY(12deg) !important;
      -webkit-filter: drop-shadow( -12px 1px 3px rgba(88, 1, 1, 0.356));
              filter: drop-shadow( -12px 1px 3px rgba(88, 1, 1, 0.356));
    }
  }

  @media only screen and (max-width: 600px) {
    .menu-title-containers > a > h2{
      font-size: 2rem;
    }
    #menu-modal{
      min-width: 100% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    #menu-modal{
      min-width: 80%;
    }
    #menu-container{
      top: 6rem;
      margin: auto 0 auto 0;
    }
    .menu-title-containers > a > h2{
      margin-right: 2.5rem;
      font-size: 2.5rem;
    }
}
#landing-text-travel {
    font-size: max(6rem, 12vw) !important;
}

#trav1{
    width: 30vw;
    background-color: rgba(93, 132, 134, 0.452);
}
#trav2{
    width: 27vw;
    background-color: rgba(6, 99, 187, 0.507);
}
#trav3{
    width: 27vw;
    background-color: rgba(207, 117, 14, 0.432);
}
#trav4{
    width: 30vw;
    background-color: rgba(70, 247, 223, 0.432);
}
#trav5{
    width: 80vw;
}
#trav6{
    width: 40vw;
    background-color: rgba(252, 187, 66, 0.479);
}
#trav7{
    width: 37vw;
    background-color: rgba(0, 0, 0, 0.212);
}
#trav8{
    width: 50vw;
    background-color: rgba(236, 177, 100, 0.541);
}
#trav9{
    width: 66vw;
    background-color: rgba(95, 158, 160, 0.74);
}
#trav10{
    width: 40vw;
    background-color: gold;
}
#trav11 {
    width: 50vw;
    background-color: rgba(0, 109, 128, 0.438);
}
#trav12 {
    width: 30vw;
    background-color: rgba(0, 0, 0, 0.603);
}
#trav13 {
    width: 70vw;
    background-color: rgba(250, 128, 114, 0.637);
}
#t1-container:hover,
#t3-container:hover{
    transform: rotate(1deg);
}
#t2-container:hover{
    transform: rotate(-0.5deg) translateY(0.5em) scale(0.99);
}
#t4-container:hover{
    transform: translateX(1em) translateY(1em);
}
#t5-container:hover .i-text,
#t10-container:hover .i-text,
#t11-container:hover .i-text
{
    outline: .6em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -.6em;
}
#t6-container:hover,
#t12-container:hover{
    transform: translate(5em) scale(1.1);
}
#t7-container:hover,
#t11-container:hover{
    transform: translate(-1.3em) scale(0.98);
}
#t8-container:hover{
    transform: translateY(1em) rotate(-0.6deg) scale(0.98);
}
#t9-container:hover,
#t13-container:hover{
    transform: scale(0.95);
}
#landing-text-presenter {
    font-size: max(6rem, 12vw) !important;
    -webkit-filter: drop-shadow(0.1rem 0rem 1rem rgba(0, 0, 0, 0.808));
            filter: drop-shadow(0.1rem 0rem 1rem rgba(0, 0, 0, 0.808));
}
#pres1{
    width: 40vw;
    background-color: rgba(216, 205, 143, 0.63);
}
#pres2{
    width: 45vw;
    background-color: lightblue;
}
#pres3{
    width: 55vw;
    background-color: rgba(70, 228, 194, 0.527);
}
#scran-podcast{
    margin: auto;
    padding-right: 5vw;
}
#p1-container:hover{
    transform: translate(4em) scale(0.95);
}
#p2-container:hover {
    transform: translate(-1rem) scale(0.99) rotate(1deg);
}
#p3-container:hover {
    transform: translate(0.5em) translateY(0.5em);
}

#p3-container:hover .i-text{
    outline: 1em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -0.5em;
}

#bbc-reel{
    width: 90%;
    height: 90%;
}
.ext-iframe {
    margin: auto;
}
#food1{
    width: 40vw;
    background-color: rgba(11, 139, 199, 0.473);
}
#food2{
    width: 35vw;
    background-color: rgba(128, 128, 128, 0.384);
}
#food3{
    width: 50vw;
    background-color: rgba(0, 0, 0, 0.699);
}
#food4{
    width: 90vw;
    background-color: rgba(172, 107, 23, 0.678);
}
#food5{
    width: 40vw;
    background-color: rgba(175, 202, 131, 0.555);
}
#food6{
    width: 40vw;
    background-color: rgba(0, 0, 0, 0.493);
}
#food7{
    width: 40vw;
    background-color: rgba(255, 127, 80, 0.384);
}
#food8{
    width: 35vw;
    background-color: rgba(137, 56, 204, 0.185);
}
#food9{
    width: 56vw;
    background-color: rgba(223, 186, 25, 0.555);
}
#food10
{
    width: 40vw;
    background-color: rgba(247, 222, 113, 0.911);
}
#food11
{
    width: 40vw;
    background-color: rgba(223, 137, 25, 0.555);
}
#food12
{
    width: 60vw;
    background-color: rgba(102, 155, 113, 0.555);
}
#food13
{
    width: 45vw;
    background-color: rgba(0, 0, 0, 0.301);
}
#food14
{
    width: 30vw;
    background-color: rgba(104, 197, 209, 0.555);
}
#food15
{
    width: 40vw;
    background-color: rgba(86, 113, 155, 0.555);
}
#food16
{
    width: 55vw;
    background-color: rgba(233, 217, 145, 0.555);
}
#food17
{
    width: 25vw;
    background-color: rgba(181, 120, 209, 0.555);
}
#food18
{
    width: 60vw;
    background-color: rgba(223, 186, 25, 0.555);
}
#food19
{
    width: 70vw;
    background-color: rgba(162, 201, 206, 0.555);
}
#food20
{
    width: 30vw;
    background-color: rgba(0, 0, 0, 0.322);
}
#food21
{
    width: 30vw;
    background-color: rgba(154, 170, 111, 0.555);
}
#food22
{
    width: 30vw;
    background-color: rgba(223, 71, 25, 0.555);
}
#food23
{
    width: 30vw;
    background-color: rgba(224, 154, 107, 0.555);
}
#food24
{
    width: 35vw;
    background-color: rgba(201, 93, 79, 0.555);
}
#food25
{
    width: 25vw;
    background-color: rgba(165, 106, 155, 0.555);
}
#food26
{
    width: 45vw;
    background-color: rgba(87, 141, 148, 0.555);
}
#food27
{
    width: 20vw;
    background-color: rgba(212, 199, 138, 0.555);
}
#food28
{
    width: 20vw;
    background-color: rgba(0, 0, 0, 0.329);
}
#food29
{
    width: 55vw;
    background-color: rgba(127, 151, 83, 0.473);
}
#food30
{
    width: 45vw;
    background-color: rgba(58, 58, 58, 0.555);
}
#food31
{
    width: 30vw;
    background-color: rgba(202, 135, 174, 0.555);
}
#food32
{
    width: 35vw;
    background-color: rgba(138, 187, 194, 0.555);
}
#food33
{
    width: 35vw;
    background-color: rgba(123, 177, 112, 0.555);
}
#food34
{
    width: 35vw;
    background-color: rgba(216, 114, 67, 0.555);
}
#food35
{
    width: 35vw;
    background-color: rgba(224, 159, 197, 0.555);
}
#food36
{
    width: 55vw;
    background-color: rgba(34, 34, 34, 0.555);
}


#f3-container:hover .i-text,
#f4-container:hover .i-text,
#f16-container:hover .i-text,
#f19-container:hover .i-text,
#f24-container:hover .i-text,
#f26-container:hover .i-text,
#f31-container:hover .i-text,
#f36-container:hover .i-text

{
    outline: 1em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -1em;
}
#f1-container:hover{
    transform: translate(1em) translateY(1em) scale(1.05);
}
#f2-container:hover,
#f11-container:hover,
#f16-container:hover,
#f19-container:hover,
#f21-container:hover,
#f35-container:hover
{
    transform: translate(-0.4em) translateY(0.5em) rotate(-0.6deg);
}
#f3-container:hover,
#f10-container:hover,
#f14-container:hover,
#f18-container:hover,
#f32-container:hover,
#f36-container:hover {
    transform: translate(0.5em) translateY(0.5em);
    
}
#f5-container:hover,
#f24-container:hover,
#f27-container:hover
{
    transform: translateY(1.5em) scale(1.1);
}
#f6-container:hover,
#f12-container:hover,
#f15-container:hover,
#f17-container:hover,
#f25-container:hover,
#f30-container:hover,
#f33-container:hover
{
    transform: translate(3em) rotate(0.3deg) scale(1.1);
}
#f7-container:hover,
#f20-container:hover,
#f23-container:hover
{
    transform: translate(-1em) translateY(-1em);
}
#f8-container:hover{
    transform: scale(0.98) rotate(-1deg) translate(0.5em);
}
#f9-container:hover,
#f13-container:hover,
#f22-container:hover
{
    transform: translate(-3em) scale(1.1);
}

#f28-container:hover,
#f34-container:hover
{
    transform: translateY(3.5rem) scale(1.1);
}
#f29-container:hover{
    transform: translate(-4rem) scale(1.05);
}
#f3-text, #f4-text{
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    padding: 0;
    outline: 0em;
    outline-offset: 0em;
}
.large-font p {
    font-size: 4.5vw;
    padding: 2.5vw;
}
.smaller-font p{
    font-size: 3vw;
    padding: 2vw;
}
#about-wrapper{
    margin-top: 12vw;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    z-index: 90;

}
#head-shot{
    position: absolute;
    height: 35.7vw;
    width: 39vw;
    min-width: 200px;
    min-height: 180px;
    background-color: rgb(253, 161, 132);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 5rem 2rem rgba(0, 0, 0, 0.26);
}
#head-shot img{
    width: 95%;
    outline: 0.15em rgb(218, 218, 218) solid;
    outline-offset: -1em;
    -webkit-filter: brightness(140%) saturate(85%) contrast(75%);
            filter: brightness(140%) saturate(85%) contrast(75%);
}
#about-section{
    width: 80%;
    right: 10%;
    top: 0;
    height: 100%;
    display: block;
    flex-direction: column;
    margin: 0 auto 0 auto;
    color: rgb(77, 77, 77);
    
}
#about-section p{
    text-align: justify;
    font-size: 1.3rem;
    font-family: 'Roboto';
    font-weight: 300;
    margin-bottom: 10rem;
    line-height: 1.4;
}
#about-section p span{
    font-size: 2rem;
    font-family: 'Abril Fatface';
    line-height: 0.9;
}
#about-section h1{
    margin: 2vh 0 0 0;
    font-family: '';
    font-size: 7rem;
    text-align: center;
    font-family: 'Abril Fatface';
    
}
@media only screen and (max-width: 1000px) and (orientation: portrait){
    #about-section p{
        font-size: 1rem;
    }
    #about-section p span{
        font-size: 2rem;
    }
    #about-section h1{
        font-size: 3rem;
    }
}
#about-section-wrapper{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 55vw;
}
.contact-logo{
    width: 10vw;
    min-width: 90px;
    min-height: 90px;
    height: 10vw;
    transition: 0.4s ease all;
    padding: 1.2vw;
    border-radius: 15%;
    cursor: pointer;
}
.contact-logo:hover{
    transform: scale(1.05);
    background-color: rgba(30, 61, 87, 0.404);
}
#contacts-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    /* z-index: 90; */
}
#contacts{
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-content: center;
    width: 70vw;
    height: 90vh;
    padding: 5vh 15vw 5vh 15vw;
    z-index: 90;
}
.designed{
    position: absolute;
    width: 100%;
    padding: 1em;
    text-align: center;
    bottom: 0;
    padding-bottom: 2vh;
    font-weight: 700;
    color: black;
    transition: 0.4s ease all;
    font-family: 'Oswald';
    /* font-size: 3vw; */
}
.designed:visited{
    color: black;
}
.designed:hover{
    color: rgb(136, 55, 243);
}
#contact-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.829);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 400;
}
.contact-form {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.521);
}
form.contact-form {
    padding: 2rem;
    border-radius: 5px;
    min-width: 280px;
    min-height: 500px;
    background-image: linear-gradient(to bottom right, rgba(168, 223, 236, 0.815), rgba(87, 182, 189, 0.815));
}

@media screen and (max-width: 768px) and (orientation: landscape){
    form.contact-form {
        width: 80%;
        max-width: 500px !important;
        max-height: 350px !important;
        min-height: 300px !important;
    }
    .contact-form > input,
    .contact-form > textarea
    {
        width: 100%;
        font-size: 90% !important;
        padding: 0.2rem !important;
    }
    .contact-form > textarea {
        height: 25vh;
    }
    .contact-form > button {
        cursor: pointer;
        margin-top: 0.5rem !important;
        font-size: 1.1rem !important;
    }

  }
.contact-form * {
    outline: none;
    border: none;
    font-family: 'Oswald';
}
.contact-form > input,
.contact-form > textarea
{
    width: 100%;
    font-size: 110%;
    box-shadow: -0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.219);
    border-radius: 3px;
    padding: 0.4rem;
}
.contact-form > h2 {
    color: rgb(82, 73, 69);
    margin-top: 0;
}

.contact-form > button {
    background-color: white;
    cursor: pointer;
    margin-top: 3rem;
    padding: 0.2rem 2rem;
    font-size: 1.2rem;
    border-radius: 20px;
    transition: .2s all;
    box-shadow: -0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.219);
}
.contact-form > button:hover,
.contact-form > button:focus
{
    background-color: black;
    color: white;
    transform: scale(1.1) translateY(-5px);
    box-shadow: -0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.445);
}
.contact-form > button:active {
    background-color: rgb(56, 55, 55);
    transform: scale(1.05) translateY(-2px);
    box-shadow: 0rem 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.719);
}
.contact-form *:not(:last-child) {
    margin-bottom: 1rem;
}
.social-modal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.767);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    z-index: 305;
    overflow: scroll;
}
#twitter{
    width: 50% !important;
    min-width: 300px;
    height: 90vh !important;
    flex-direction: column !important;
    justify-content: space-around !important;
    align-content: center !important;
    margin: auto auto !important;
    overflow: scroll;
    border-radius: 0.5em;
}
#instagram{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 80%;
    height: 150%;
}
#instagram > div {
    height: 50% !important;
}
#instagram > a{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10%;
    justify-content: space-around;
}
#instagram > a > iframe{
    height: 40vw;
    width: 80vw;
    position: relative;
    pointer-events: none;
    margin: auto;
}
.snapwidget-widget {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 900px){
    #instagram > a > iframe{
        margin: auto;
        height: 60vw;
        pointer-events: none;

    }
}
@media screen and (max-width: 400px){
    #instagram > a > iframe{
        margin: auto;
        position: relative;
        height: 70vw;
        pointer-events: none;
        /* background-color: white; */
        overflow: scroll;
    }
}
@media screen and (max-width: 569px) and (orientation: landscape){
    #instagram > a > iframe{
        margin: auto;
        position: relative;
        width: 85vh;
        height: 70vh;
    }
}
@media screen and (max-width: 737px) and (orientation: landscape){
    #instagram > a > iframe{
        margin: auto;
        position: relative;
        width: 100vh;
        height: 77vh;
    }
}

.css-xgm0sd {
    display: none !important;
    opacity: 0 !important;
    z-index: -9999;
}

