#food1{
    width: 40vw;
    background-color: rgba(11, 139, 199, 0.473);
}
#food2{
    width: 35vw;
    background-color: rgba(128, 128, 128, 0.384);
}
#food3{
    width: 50vw;
    background-color: rgba(0, 0, 0, 0.699);
}
#food4{
    width: 90vw;
    background-color: rgba(172, 107, 23, 0.678);
}
#food5{
    width: 40vw;
    background-color: rgba(175, 202, 131, 0.555);
}
#food6{
    width: 40vw;
    background-color: rgba(0, 0, 0, 0.493);
}
#food7{
    width: 40vw;
    background-color: rgba(255, 127, 80, 0.384);
}
#food8{
    width: 35vw;
    background-color: rgba(137, 56, 204, 0.185);
}
#food9{
    width: 56vw;
    background-color: rgba(223, 186, 25, 0.555);
}
#food10
{
    width: 40vw;
    background-color: rgba(247, 222, 113, 0.911);
}
#food11
{
    width: 40vw;
    background-color: rgba(223, 137, 25, 0.555);
}
#food12
{
    width: 60vw;
    background-color: rgba(102, 155, 113, 0.555);
}
#food13
{
    width: 45vw;
    background-color: rgba(0, 0, 0, 0.301);
}
#food14
{
    width: 30vw;
    background-color: rgba(104, 197, 209, 0.555);
}
#food15
{
    width: 40vw;
    background-color: rgba(86, 113, 155, 0.555);
}
#food16
{
    width: 55vw;
    background-color: rgba(233, 217, 145, 0.555);
}
#food17
{
    width: 25vw;
    background-color: rgba(181, 120, 209, 0.555);
}
#food18
{
    width: 60vw;
    background-color: rgba(223, 186, 25, 0.555);
}
#food19
{
    width: 70vw;
    background-color: rgba(162, 201, 206, 0.555);
}
#food20
{
    width: 30vw;
    background-color: rgba(0, 0, 0, 0.322);
}
#food21
{
    width: 30vw;
    background-color: rgba(154, 170, 111, 0.555);
}
#food22
{
    width: 30vw;
    background-color: rgba(223, 71, 25, 0.555);
}
#food23
{
    width: 30vw;
    background-color: rgba(224, 154, 107, 0.555);
}
#food24
{
    width: 35vw;
    background-color: rgba(201, 93, 79, 0.555);
}
#food25
{
    width: 25vw;
    background-color: rgba(165, 106, 155, 0.555);
}
#food26
{
    width: 45vw;
    background-color: rgba(87, 141, 148, 0.555);
}
#food27
{
    width: 20vw;
    background-color: rgba(212, 199, 138, 0.555);
}
#food28
{
    width: 20vw;
    background-color: rgba(0, 0, 0, 0.329);
}
#food29
{
    width: 55vw;
    background-color: rgba(127, 151, 83, 0.473);
}
#food30
{
    width: 45vw;
    background-color: rgba(58, 58, 58, 0.555);
}
#food31
{
    width: 30vw;
    background-color: rgba(202, 135, 174, 0.555);
}
#food32
{
    width: 35vw;
    background-color: rgba(138, 187, 194, 0.555);
}
#food33
{
    width: 35vw;
    background-color: rgba(123, 177, 112, 0.555);
}
#food34
{
    width: 35vw;
    background-color: rgba(216, 114, 67, 0.555);
}
#food35
{
    width: 35vw;
    background-color: rgba(224, 159, 197, 0.555);
}
#food36
{
    width: 55vw;
    background-color: rgba(34, 34, 34, 0.555);
}


#f3-container:hover .i-text,
#f4-container:hover .i-text,
#f16-container:hover .i-text,
#f19-container:hover .i-text,
#f24-container:hover .i-text,
#f26-container:hover .i-text,
#f31-container:hover .i-text,
#f36-container:hover .i-text

{
    outline: 1em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -1em;
}
#f1-container:hover{
    transform: translate(1em) translateY(1em) scale(1.05);
}
#f2-container:hover,
#f11-container:hover,
#f16-container:hover,
#f19-container:hover,
#f21-container:hover,
#f35-container:hover
{
    transform: translate(-0.4em) translateY(0.5em) rotate(-0.6deg);
}
#f3-container:hover,
#f10-container:hover,
#f14-container:hover,
#f18-container:hover,
#f32-container:hover,
#f36-container:hover {
    transform: translate(0.5em) translateY(0.5em);
    
}
#f5-container:hover,
#f24-container:hover,
#f27-container:hover
{
    transform: translateY(1.5em) scale(1.1);
}
#f6-container:hover,
#f12-container:hover,
#f15-container:hover,
#f17-container:hover,
#f25-container:hover,
#f30-container:hover,
#f33-container:hover
{
    transform: translate(3em) rotate(0.3deg) scale(1.1);
}
#f7-container:hover,
#f20-container:hover,
#f23-container:hover
{
    transform: translate(-1em) translateY(-1em);
}
#f8-container:hover{
    transform: scale(0.98) rotate(-1deg) translate(0.5em);
}
#f9-container:hover,
#f13-container:hover,
#f22-container:hover
{
    transform: translate(-3em) scale(1.1);
}

#f28-container:hover,
#f34-container:hover
{
    transform: translateY(3.5rem) scale(1.1);
}
#f29-container:hover{
    transform: translate(-4rem) scale(1.05);
}
#f3-text, #f4-text{
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    padding: 0;
    outline: 0em;
    outline-offset: 0em;
}
.large-font p {
    font-size: 4.5vw;
    padding: 2.5vw;
}
.smaller-font p{
    font-size: 3vw;
    padding: 2vw;
}