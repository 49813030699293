#landing-text-interiors {
    font-size: max(7rem, 12vw) !important;
    filter: drop-shadow(0.1rem 0rem 1rem black);
}
#ints1{
    width: 35vw;
    min-width: 600px;
    background-color: rgba(199, 236, 247, 0.541);
  }
  
#ints2{
    background-color: rgba(252, 217, 217, 0.562);
    width: 70%;
  }
#ints3{
    background-color: rgba(157, 179, 132, 0.349);
    width: 43%;
}
#ints4{
    width: 37%;
    background-color: rgb(0, 0, 0);
}
#ints5{
    width: 80%;
}

#ints6{
    width: 45%;
    background-color: rgba(255, 215, 142, 0.699);
}
/* #ints7{
    width: 35%;
    background-color: rgba(233, 139, 201, 0.623);
} */
#ints8{
    width: 35%;
    background-color: rgba(158, 187, 121, 0.349);
}
#ints9{
    width: 82%;
    background-color: rgba(143, 33, 33, 0.432);
}
#ints10{
    width: 40%;
    background-color: rgba(221, 81, 174, 0.24);
}
#ints11{
    width: 25%;
    background-color: rgba(255, 238, 0, 0.486);
}
#ints12{
    width: 40%;
    background-color: rgba(108, 192, 171, 0.562);
}
#ints13{
    width: 50%;
    background-color: rgb(235, 235, 235);
}
#ints14{
    width: 30%;
    background-color: rgba(255, 99, 71, 0.315);
}
#ints15{
    width: 50%;
}
#ints16{
    width: 50%;
    background-color: rgba(194, 133, 77, 0.541) ;
}
#ints17{
    width: 30vw;
    background-color: rgba(7, 197, 0, 0.356);
}
#ints18{
    width: 50vw;
    background-color: rgba(100, 100, 100, 0.445);
}
#ints19{
    width: 30vw;
    background-color: rgba(197, 0, 0, 0.356);
}
#ints20 {
    width: 30vw;
    background-color: rgba(33, 54, 121, 0.486);
}
#i5-container:hover .i-text,
#i12-container:hover .i-text,
#i15-container:hover .i-text
{
    outline: 1em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -1em;
}
#i5-text, #i12-text, #i15-text{
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    padding: 0;
    outline: 0em;
    outline-offset: 0em;
}
#i5-text p, #i12-text p, #i15-text p{
    width: 90% !important;
    position: relative !important;
}
#i2-container:hover, 
/* #i7-container:hover,  */
#i11-container:hover, 
#i17-container:hover{
    transform: translateX(-1em) translateY(1em) rotateZ(0.3deg);
}
#i3-container:hover, #i1-container:hover, #i6-container:hover{
    transform: translateX(1em) translateY(-1em) rotateZ(-1deg);
}
#i4-container:hover{
  transform: translate(-2em);
}
#i8-container:hover{
    transform: translate(2em);
}
#i9-container:hover
{
    transform: translate(-7em) scale(1.02);
}
#i10-container:hover{
    transform: translate(1em) translateY(1em) rotate(0.7deg);
}
#i13-container:hover{
    transform: translateY(1em) scale(0.98);
}
#i14-container:hover{
    transform: translate(-1em) translateY(-1em) rotate(0.7deg);
}
#i18-container:hover{
    transform: scale(0.95);
}
#i19-container:hover{
    transform: translateY(-2vw) scale(0.95);
}
#i9-container:hover,
#i16-container:hover
{
    transform: translate(-3em) scale(1.02);
}
#i4-text p{
    font-size: 4vw;
}
#i8-text p{
    font-size: 3.5vw;
}