#about-wrapper{
    margin-top: 12vw;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    z-index: 90;

}
#head-shot{
    position: absolute;
    height: 35.7vw;
    width: 39vw;
    min-width: 200px;
    min-height: 180px;
    background-color: rgb(253, 161, 132);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 5rem 2rem rgba(0, 0, 0, 0.26);
}
#head-shot img{
    width: 95%;
    outline: 0.15em rgb(218, 218, 218) solid;
    outline-offset: -1em;
    filter: brightness(140%) saturate(85%) contrast(75%);
}
#about-section{
    width: 80%;
    right: 10%;
    top: 0;
    height: 100%;
    display: block;
    flex-direction: column;
    margin: 0 auto 0 auto;
    color: rgb(77, 77, 77);
    
}
#about-section p{
    text-align: justify;
    font-size: 1.3rem;
    font-family: 'Roboto';
    font-weight: 300;
    margin-bottom: 10rem;
    line-height: 1.4;
}
#about-section p span{
    font-size: 2rem;
    font-family: 'Abril Fatface';
    line-height: 0.9;
}
#about-section h1{
    margin: 2vh 0 0 0;
    font-family: '';
    font-size: 7rem;
    text-align: center;
    font-family: 'Abril Fatface';
    
}
@media only screen and (max-width: 1000px) and (orientation: portrait){
    #about-section p{
        font-size: 1rem;
    }
    #about-section p span{
        font-size: 2rem;
    }
    #about-section h1{
        font-size: 3rem;
    }
}
#about-section-wrapper{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 55vw;
}