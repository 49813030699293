#landing-image{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    z-index: 1;
  }
  #landing-image img{
    position: absolute;
    height: 120%;
  }

  #presenter {
    left: 50%;
    transform: translate(-50%);
  }
  .header-wrapper{
    height:20%;
    width: 100%;
    position: absolute;
    top: 65vh;
    display: flex; 
    flex-flow: column;
    justify-content: space-around;
  }
  .header-wrapper > h1{
    text-align: center;
    width: 100%;
    height: fit-content;
    position: relative;
    font-size: clamp(3.5rem, 10vw, 15rem);
    color: white;
    font-family:  'Sacramento', Arial, Helvetica, sans-serif;
    display: inline;
    margin: 0;
    margin-block: 0 !important;
  }
  .header-wrapper> h2{
    margin: 0;
    margin-block: 0;
    width: 100%;
    font-family: 'Special Elite';
    position: relative;
    font-size: 3vw;
    color: white;
    text-align: center;
  }

  #writer{
    display: inline-block;
    width: 11vw !important;
  }
  .Typewriter{
    margin-right: 0.1em ;
    display: inline;
  }
  
  @media only screen and (max-width: 768px) {
    #landing-image{
      position: absolute;
    }
  }
  @media screen and (orientation:portrait) {
    #landing-image{
      height: 100vh;
    }
    #landing-image img{
      height: 100%;
      margin-left: -25%;
    }
    .header-wrapper{
      top: 65vh !important;
    }
  }
  @media screen and (orientation:landscape) {
    #landing-image{
      height: 125vh;
    }
  }