#landing-text-travel {
    font-size: max(6rem, 12vw) !important;
}

#trav1{
    width: 30vw;
    background-color: rgba(93, 132, 134, 0.452);
}
#trav2{
    width: 27vw;
    background-color: rgba(6, 99, 187, 0.507);
}
#trav3{
    width: 27vw;
    background-color: rgba(207, 117, 14, 0.432);
}
#trav4{
    width: 30vw;
    background-color: rgba(70, 247, 223, 0.432);
}
#trav5{
    width: 80vw;
}
#trav6{
    width: 40vw;
    background-color: rgba(252, 187, 66, 0.479);
}
#trav7{
    width: 37vw;
    background-color: rgba(0, 0, 0, 0.212);
}
#trav8{
    width: 50vw;
    background-color: rgba(236, 177, 100, 0.541);
}
#trav9{
    width: 66vw;
    background-color: rgba(95, 158, 160, 0.74);
}
#trav10{
    width: 40vw;
    background-color: gold;
}
#trav11 {
    width: 50vw;
    background-color: rgba(0, 109, 128, 0.438);
}
#trav12 {
    width: 30vw;
    background-color: rgba(0, 0, 0, 0.603);
}
#trav13 {
    width: 70vw;
    background-color: rgba(250, 128, 114, 0.637);
}
#t1-container:hover,
#t3-container:hover{
    transform: rotate(1deg);
}
#t2-container:hover{
    transform: rotate(-0.5deg) translateY(0.5em) scale(0.99);
}
#t4-container:hover{
    transform: translateX(1em) translateY(1em);
}
#t5-container:hover .i-text,
#t10-container:hover .i-text,
#t11-container:hover .i-text
{
    outline: .6em rgba(255, 255, 255, 0.15) solid;
    outline-offset: -.6em;
}
#t6-container:hover,
#t12-container:hover{
    transform: translate(5em) scale(1.1);
}
#t7-container:hover,
#t11-container:hover{
    transform: translate(-1.3em) scale(0.98);
}
#t8-container:hover{
    transform: translateY(1em) rotate(-0.6deg) scale(0.98);
}
#t9-container:hover,
#t13-container:hover{
    transform: scale(0.95);
}